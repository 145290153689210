<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="lottieDivWrap text-center position-relative" >
        <div class=" ">
          <div id="lottieDivs" class="m-auto" style="height:400px"></div>
          <h2 class="mt-4 mb-1">Sorry, page not found</h2>
          <p class="text-muted">We're sorry, but the page you are looking for does not exist.</p>
          </div>
      </div>

      </div>
    </div>
   
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Common from "@/components/common";
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    PageHeader, 
    Common
  },
  page() {
    return {
      title: "Error 404",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      path:"animate/404.json",
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '404',
          active: true,
        },
      ],
    };
  },
  middleware: "authentication",
  mounted(){
    //this.getData()
    var svgContainer = document.getElementById('lottieDivs');
    var animation = window.bodymovin.loadAnimation({
      container: svgContainer,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: this.path
    })
    animation.play()
  },
  created(){
    
  },
  methods:{
  }
};
</script>